@use 'src/theme/mixins';

.fullHeight {
  min-height: 915px;
}

.title {
  max-width: 500px;
  margin: 0 auto;
}

.disbursement {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  svg {
    width: 100px;
    height: 100px;
    font-size: 100px;
    color: #dc3545;
  }
  p {
    font-size: 36px;
    line-height: 54px;
  }
  span {
    font-size: 16px;
    line-height: 24px;
  }
}

.disbursement__buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}

@media screen and (max-width: 768px){
    .fullHeight {
      min-height: 915px;
    }
}

@media screen and (max-width: 462px){
    .fullHeight {
      min-height: 1010px;
    }
}

@media screen and (max-width: 408px){
    .fullHeight {
      min-height: 1045px;
    }
}

@media screen and (max-width: 344px){
    .fullHeight {
      min-height: 1085px;
    }
}

.fullHeightCompleted {
  min-height: 545px;
}

@media screen and (max-width: 768px){
    .fullHeightCompleted {
      min-height: 545px;
    }
}

@media screen and (max-width: 462px){
    .fullHeightCompleted {
      min-height: 545px;
    }
}

@media screen and (max-width: 408px){
    .fullHeightCompleted {
      min-height: 545px;
    }
}

@media screen and (max-width: 344px){
    .fullHeightCompleted {
      min-height: 585px;
    }
}
