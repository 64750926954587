@use 'src/theme/variables';
@use 'src/theme/fonts';

.checkbox {
  margin-top: 4px;
}

.checkboxGridLabel p {
  color: variables.$dark;
  margin: 0;
  font-size: fonts.$font-size-base;
}

.recaptchaWrapper > div {
  display: inline-block;
}

.button {
  width: 180px;
  margin-bottom: variables.$spacing-large;
}

a {
  color: variables.$primary;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
}

.hidden { display:none; }

.createAccountText {
  font-size: 0.7em;
  max-width: 250px;
}

.submitButton {
  max-width: 250px;
  margin: auto;
}
