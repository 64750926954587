@use 'src/theme/fonts';
@use 'src/theme/variables';

.container {
  width: 100%;
  padding: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.details {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 12px;
}

.input-center {
  width: 15%;
  margin: 0 auto;
}

.action {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #5558AF;
  line-height: 1.5;
  font-size: fonts.$font-size-lg;
  font-family: fonts.$font-family-base;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  background-color: transparent;
  transition: .15s all ease-in-out;
  white-space: nowrap;
  svg {
    width: 18px;
    height: 18px;
    color: #5558AF;
  }
  :global(.spinner-border) {
    width: 18px;
    height: 18px;
    border-width: 2px;
  }
  &:hover {
    background-color: #eeeff8;
  }
  &:focus {
    outline: 2px solid #6f72c4;
  }
  &:disabled {
    cursor: default;
    color: #9D9D9D;
    background-color: transparent;
    svg {
      color: #9D9D9D;
    }
  }
}

@media (max-width: variables.$breakpoint-md) {
  .container {
    flex-direction: column;
  }

  .details {
    grid-template-columns: auto;
    justify-items: center;
  }

  .title, .description {
    text-align: center;
  }

  .action {
    white-space: break-spaces;
  }
}

.textContainer {
  display: grid;
  gap: 10px;
}

.title {
  color: #3C3C3C;
  line-height: 1.2;
  font-size: fonts.$h3-font-size;
  font-family: fonts.$font-family-bold;
}

.description {
  color: #212529;
  line-height: 1.5;
  font-size: fonts.$font-size-lg;
  font-family: fonts.$font-family-base;
}

.actionCompleted {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #53AB61;
  line-height: 1.5;
  font-size: fonts.$font-size-lg;
  font-family: fonts.$font-family-base;
  svg {
    width: 18px;
    height: 18px;
    color: #53AB61;
  }
}
