@use 'src/theme/mixins';

.Row {
  @include mixins.media-breakpoint-down(xl) {
    margin-bottom: 2rem !important;
  }

  @include mixins.media-breakpoint-down(sm) {
    margin-bottom: 0 !important;
  }
}

.EmploymentConfirmationDiv {
  @include mixins.media-breakpoint-down(xl) {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }

  @include mixins.media-breakpoint-down(sm) {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.ImageGif {
  height: 40px;
  width: 40px;
}

.VerificationText {
  color: green
}

.PhoneContainer {
  display: flex;
  align-items: center;
  gap: 4rem;
  @include mixins.media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

.verificationTextContainer {
  width: 35%;
  font-size: 0.70rem;
  font-weight: bold;
}

.CardBody {
  margin-top: 0rem !important;
}


.IncomeHeader {
  margin-top: 4rem !important;
  margin-bottom: 0rem !important;
}

.CheckboxContainer {
  padding-left: 0rem !important;
}
