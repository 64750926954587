@use 'src/theme/mixins';
@use 'src/theme/variables';

.ModalHeader {
  text-align: center;

  h2 {
    color: variables.$primary
  }

  .WarningSign {
    margin-bottom: 0.2em;
  }
}
