@use 'theme/_variables';

.refinanceTooltip {
  display: inline;
  top: 3%;
  right: 2%;
  line-height: 2rem;
  width: 3.5rem;
  text-align: center;
  border-radius: 50%;
  background-color: variables.$background-color;
  color: variables.$primary;
  position: absolute;
}
