@use 'src/theme/variables';

.message {
  color: variables.$primary;
  font-weight: 600;
  text-align: center;
  margin: auto;
  display: table;
  padding-bottom: 0.5rem;

}

@media (max-width: 920px) {
  .message {
    font-size: 0.86rem;
  }
}
