@use 'src/theme/fonts';
@use 'src/theme/variables';

.title {
  line-height: 1.2;
  color: variables.$dark !important;
  font-size: fonts.$h2-font-size;
  font-family: fonts.$font-family-base;
}

@media (max-width: variables.$breakpoint-lg) {
  .title {
    font-size: fonts.$h3-font-size;
  }
}
