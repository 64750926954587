@use 'variables';
@use 'fonts';
@use 'mixins';

@mixin card-padding {
  padding: 0 variables.$spacing-xlarge;

  @include mixins.media-breakpoint-down(xs) {
    padding: 0 variables.$spacing-large;
  }
}

.card-container {
  max-width: 600px !important;
  width: 100%;
}

.card {
  // top and bottom padding controlled by .card
  // while side padding is set by header, body, and footer
  padding: variables.$spacing-large 0;
  border: none;
  border-radius: 10px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, .04);

  &.no-shadow {
    box-shadow: none;
  }

  &.full-page {
    min-height: 600px;
    margin-bottom: variables.$spacing-large;
    justify-content: center;

    @include mixins.media-breakpoint-down(md) {
      min-height: 0;
    }
  }

  &.bg-dark.text-white {
    span, p, h1, h2, h3, h4, h5, h6 {
      color: $white;
    }
  }

  .card-header {
    @include card-padding;
    border-bottom: none;
    background-color: variables.$background-color;

    h1 {
      color: variables.$dark;
      margin-bottom: variables.$spacing-small;
    }
    p {
      margin: 0 auto;
      font-size: fonts.$font-size-lg;
      max-width: 530px;
    }
    &.allow-full-width{
      p{
        max-width: 100%;
      }
    }
  }

  .card-body {
    @include card-padding;
    margin: variables.$spacing-large 0;

    @include mixins.media-breakpoint-down(xs) {
      margin: variables.$spacing-medium 0;
    }
  }

  .card-footer {
    @include card-padding;
    background-color: variables.$background-color;
    border-top: none;
  }

  @include mixins.media-breakpoint-down(sm) {
    .btn {
      width: 100%;
    }

    /* :not() has poor support in key browsers */
    .btn.ignore-block {
      width: auto;
    }
  }
}

.alert.alert-success {
  color: variables.$primary;
  border: 2px solid variables.$success;
}

.alert.alert-success:focus {
  outline: 3px solid #9de8cf;
  border: none;
}
