@use 'variables';

/* SCSS Variables */
$font-family-sans-serif: 'Poppins Regular', 'Helvetica Neue', 'Roboto',
  sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: 'Poppins Regular', 'Helvetica Neue', 'Roboto', sans-serif;
$font-family-bold: 'Poppins SemiBold', 'Helvetica Neue', 'Roboto', sans-serif;

$font-size-sm: 0.75rem;
$font-size-md: 1rem;
$font-size-lg: 1.125rem;
$font-size-base: $font-size-lg;
$h1-font-size: 2.5rem;
$h2-font-size: 1.8rem;
$h3-font-size: 1.5rem;

/* Custom Fonts */
@font-face {
  font-family: 'Poppins Regular';
  src: url('./../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('./../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('./../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

.family-normal {
  font-family: 'Poppins Regular', 'Helvetica Neue', 'Roboto', sans-serif;
}
.family-semi {
  font-family: 'Poppins SemiBold', 'Helvetica Neue', 'Roboto', sans-serif;
}
.family-bold {
  font-family: 'Poppins Bold', 'Helvetica Neue', 'Roboto', sans-serif;
}

body {
  overflow-x: hidden;
  font-family: $font-family-base !important;
  font-size: $font-size-base;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
b,
strong {
  font-family: 'Poppins SemiBold', 'Helvetica Neue', 'Roboto', sans-serif;
}

h3 {
  font-family: 'Poppins Bold', 'Helvetica Neue', 'Roboto', sans-serif;
}

label,
.lead {
  font-size: $font-size-lg;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

.btn {
  font-family: $font-family-bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: variables.$dark;
}

a {
  font-weight: bold;
}

.card .card-header > p.subtitle {
  line-height: 1.5rem;
  font-size: $font-size-md;
  margin: 0px;
}

.light {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    color: variables.$light;
  }

  a {
    font-weight: normal;
    color: variables.$light;
    text-decoration: underline;
  }
}

@media (max-width: 960px) {
  h1,
  .h1 {
    font-size: $h2-font-size;
  }
}
