@use 'src/theme/mixins';
@use 'src/theme/variables';

.listGroupItem {
  min-width: 25%;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;

  @include mixins.media-breakpoint-down(sm) {
    min-width: 100%;

    h4 {
      font-size: large;
    }
    p {
      font-size: medium;
    }
  }

  h4 {
    font-weight: bold;
  }
  p {
    font-weight: bold;
    font-size: small;
  }

  p:last-of-type {
    font-size: medium;
    font-weight: bolder;
  }
}

.listGroupItem:nth-child(-n + 2) {
  border-color: black;
  background-color: lightgray;
}

.table {
  tr:nth-child(1) {
    td {
      border-top: none;
    }
  }
  .expandButton {
    font-weight: bold;
    cursor: pointer;
    color: variables.$dark;
  }
}

.cardSubtitle {
  cursor: pointer;
  line-height: 1.5;
  color: #5558af;
  font-weight: 500;
  font-family: "Poppins SemiBold", "Helvetica Neue", "Roboto", sans-serif;
}

.FAExcl{
  font-size: 1.4rem;
  margin-left: 0.2rem;
  cursor: pointer;
}

@media (max-width: 410px) {
  .PaymentSchHeader{
    font-size: 1.7rem;
  }
}
