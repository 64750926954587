@use 'src/theme/variables';

$background-width: 150px;
$background-height: 150px;
$folder-width: 60px;
$folder-height: 50px;
$file-width: $folder-width - 30px;
$file-height: $folder-height - 10px;

@-webkit-keyframes fileOneMovement {
  0% {top: -$folder-width; left: -20px;}
  40% {top: -$folder-width; left: -20px;}
  60% {top: -$folder-width; left: 15px; z-index: 3;}
  61% {top: -$folder-width; left: 15px;}
  80% {top: -$folder-width;}
  100% {top:0;}
}

@keyframes fileOneMovement {
  0% {top: -$folder-width; left: -20px;}
  40% {top: -$folder-width; left: -20px;}
  60% {top: -$folder-width; left: 15px; z-index: 3;}
  61% {top: -$folder-width; left: 15px;}
  80% {top: -$folder-width;}
  100% {top:0;}
}

@-webkit-keyframes fileTwoMovement {
  0% {top: -$folder-width;}
  40% {top: -$folder-width; left: 15px;}
  61% {top: -$folder-width; z-index: 2;}
  70% {top: -$folder-width;}
  100% {top: 0;}
}

@keyframes fileTwoMovement {
  0% {top: -$folder-width;}
  40% {top: -$folder-width; left: 15px;}
  61% {top: -$folder-width; z-index: 2;}
  70% {top: -$folder-width;}
  100% {top: 0;}
}

@-webkit-keyframes fileThreeMovement {
  0% {top: -$folder-width; left: $folder-height;}
  40% {top: -$folder-width; left: $folder-height;}
  60% {top: -$folder-width; left: 15px; z-index: 1;}
  61% {top: -$folder-width; left: 15px;}
  80% {top: -$folder-width;}
  100% {top:0;}
}

@keyframes fileThreeMovement {
  0% {top: -$folder-width; left: $folder-height;}
  40% {top: -$folder-width; left: $folder-height;}
  60% {top: -$folder-width; left: 15px; z-index: 1;}
  61% {top: -$folder-width; left: 15px;}
  80% {top: -$folder-width;}
  100% {top:0;}
}

.container {
  display: flex;
  height: 75vh;
  justify-content: center;
  align-items: center;
}

.contentWrapper {
  width: 300px;
  height: 340px;
  background-color: #FFFFFF;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.folderBackground {
  width: $background-width;
  height: $background-height;
  border-radius: 50%;
  background-color: variables.$light;
  position: relative;
}

.folder {
	width: $folder-width;
	height: $folder-height;
	background-color: darken(variables.$primary, 5%);
	position: absolute;
	left: 45px;
	top: 55px;
	border-radius: 6px;
}

.folder::before {
	content: "";
	position: absolute;
	width: $folder-width;
	height: ($folder-height - 10px);
	background-color: variables.$primary;
	left: 0px;
	top: 10px;
	border-radius: 6px;
	z-index: 5;
	box-shadow: 3px -3px 5px rgba(0,0,0,0.5);
}

.folder::after {
	content: "";
	position: absolute;
	width: 60px;
	height: 20px;
	background-color: darken(variables.$primary, 5%);
	top: -8px;
	left: 0;
	z-index: 1;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.file {
  width: $file-width;
	height: $file-height;
	background-color: variables.$light;
	position: absolute;
	z-index: 2;
	left: 15px;
	top: 0px;
	border-radius: 5px;
	box-shadow: 3px 3px 5px rgba(0,0,0,0.5);
}

.fileOne {
  z-index: 3;
}

.folder .fileOne {
	-webkit-animation: fileOneMovement 1.5s linear infinite alternate;
	animation: fileOneMovement 1.5s linear infinite alternate;
}

.folder .fileTwo {
	-webkit-animation: fileTwoMovement 1.5s linear infinite alternate;
	animation: fileTwoMovement 1.5s linear infinite alternate;
}

.folder .fileThree {
	-webkit-animation: fileThreeMovement 1.5s linear infinite alternate;
	animation: fileThreeMovement 1.5s linear infinite alternate;
}
