@media print {
  .printReceipt button {
    display: none;
  }
}

.cardContainer {
  max-width: 685px !important;
  width: 100%;
}
