@use 'src/theme/mixins';
@use 'src/theme/variables';

  .ModalHeader {
  text-align: left;
  }

  .ModalBody{
    padding: 2rem !important;
  }

  h2 {
    color: variables.$dark
  }

  .TextContainer {
    text-align: left;
    padding-top: 1rem;
  }

  .ButtonContainer{
    text-align: center;
    margin-top: 1.5rem;
  }

  .CloseButton{
    width: 100%;
  }
