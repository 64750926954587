@use 'theme/fonts';

.paragraph {
  display: block;
  margin: 0 auto;
  font-size: fonts.$font-size-lg;
  max-width: 530px;

  a {
    color: #5558af !important;
    cursor: pointer;
    font-weight: normal;
  }
}

.cta_link {
  color: #5558af;
  cursor: pointer;
}

.cta_link:hover {
  text-decoration: underline;
}
