.banner{
    width: 100%;
    background: #ffdd00;
    display: flex;
    justify-content: center;
    color: #5558af;

p {
  margin: 0 !important;
  font-weight: 600;
  color: #5558af !important;
}

svg {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1.5rem !important;
    font-size: 2.5rem;
}

.bannerContentContainer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
}

