@use 'mixins';
@use 'variables';

.toast {
  border: none;
  width: 300px;
  padding: variables.$spacing-xsmall;
  transition: 0.25s ease-in;

  button {
    height: auto;
  }

  @include mixins.media-breakpoint-down(md) {
    min-width: 100%;
  }
}
