.container {
  position: relative;
}

.spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  opacity: 0;
  transition: opacity 0.1s ease-in;
}

.visible {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}
