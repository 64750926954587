@use 'src/theme/fonts';
.cardContainer {
  max-width: 685px !important;
  width: 100%;
}

.termsContainer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  padding: 0px 0px 24px;
}

.termsTitle {
  color: #3C3C3C;
  line-height: 1.5;
  font-size: fonts.$font-size-lg;
  font-family: fonts.$font-family-bold;
}

.termsText {
  color: #3C3C3C;
  line-height: 1.5;
  font-size: fonts.$font-size-lg;
  font-family: fonts.$font-family-base;
}

.termsItem {
  display: block;
  padding-left: 32px;
  position: relative;
  color: #3C3C3C;
  line-height: 1.5;
  font-size: fonts.$font-size-lg;
  font-family: fonts.$font-family-base;
  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 0px;
    top: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3C3C3C;
  }
}
