@use 'theme/_variables';
@use 'theme/_mixins';

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7rem 5rem;
  background-color: white;
  @include mixins.media-breakpoint-down(lg) {
    flex-direction: column-reverse;
    padding: 4rem 1rem;
  }
  @include mixins.media-breakpoint-down(md) {
    padding: 2rem 0rem;
  }
}

.service_button {
  font-size: 1.5rem !important;
  padding: 0.5rem !important;
  width: 265px !important;
}

.headline {
  color: variables.$primary;
  padding-bottom: 0.5rem;
  font-size: 4rem;
  @include mixins.media-breakpoint-down(lg) {
    margin-top: 2rem;
  }
}

.card p,
u {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.text_container {
  margin-left: 3rem !important;
  max-width: 35% !important;
  @include mixins.media-breakpoint-down(lg) {
    text-align: center;
    max-width: 100% !important;
    margin: 0 1rem !important;
  }
}
