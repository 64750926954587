@use 'src/theme/variables';

#amountToBecomeCurrent {
  color: variables.$primary;
  margin-top: 1em;
  display: block;
}
#customHeaderText{
  font-size: 1.8rem;
}
