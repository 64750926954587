@use 'theme/_mixins';

.documentDownload {
  @include mixins.media-breakpoint-down(md) {
    text-align: center;
    font-size: 1rem;
  }
}

@media print {
  .thankYouButton button {
    display: none;
  }

  .documentDownload button {
    display: none;
  }

  .printButton button {
    display: none;
  }
}

@include mixins.media-breakpoint-up(lg) {
  .cardContainer {
    max-width: 685px !important;
    width: 100%;
  }

  .card {
    width: 820px !important;
  }
}

.customcardbody {
  flex: 0 0 !important;
}
