@use 'src/theme/mixins';

.image{
    width: 48px;
    height: 32px;
}

.letter{
    color: red !important;
}

.logoCardContainer{
    position: relative
}

.imageCardLogo{
    position: absolute;
    width: 40px;
    height: 24px;
    top: 0;
    right: 0;
    margin-right: 17px;
    margin-top: 15px;
}

.textContainer p{
    margin-bottom: 4px;
}



@media screen and (max-width: 991px){
    .marginImage {
        margin-left: 30px;
    }
}