@use 'src/theme/variables';

.footerDisclaimer{
  background-color: white;
  padding: variables.$spacing-large;
  font-size: 0.8rem;
}
.grid {
  width: 100%;
  max-width: 100%;
  background-color: variables.$light-tint;
  border-top: 1px solid variables.$medium;
}

.footerCol {
  font-size: 0.8rem;
}

.footerCol p {
  margin: 0;
}

.footerColLeft {
  text-align: left;
  padding-left: 30px;
}

.footerColCenter {
  text-align: center;
}

.footerLogos {
  text-align: right;
  padding-right: 30px;
}

.footerLogos img {
  height: 38px;
}

@media (max-width: 920px) {
  .footerCol {
    text-align: center;
    padding: variables.$spacing-medium 0;
  }
}
