@use 'src/theme/variables';

.dropdown {
  width: 100%;
  padding-left: variables.$spacing-medium;
}
.dropdownMenu{
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 200px;
}
.dropdownText {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.dropdownSelectIcon {
  color: variables.$primary;
  stroke-width: 30px;
  margin-right: variables.$spacing-small;
}

.highlight {
  color: variables.$primary;
  font-weight: bolder;
}

.radioInput { 
  label {
    width: 100%;
    white-space: normal;
  }

  input {
    opacity: 0;
    margin-top: -8px;
    width: 100%;
    height: 43px;
  }
}
