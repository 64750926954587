@use 'fonts';
@use 'mixins';

a.button-link:hover {
  text-decoration: none;
}

.btn {
  padding: 13px 37px;
  font-size: fonts.$font-size-lg;
}

form .btn {
  height: 57px;
}

.btn-link {
  height: auto;

  &:focus {
    text-decoration: underline;
    font-weight: 700;
    box-shadow: none !important;
  }
}

@include mixins.media-breakpoint-down(md) {
  .btn {
    padding: 16px 21px;
    font-size: fonts.$font-size-md;
  }
}
