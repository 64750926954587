@use 'src/theme/fonts';
@use 'src/theme/variables';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-top: 16px;
}

.backButton {
  border: none;
  outline: none;
  padding: 8px 0px;
  cursor: pointer !important;
  background-color: transparent;
  line-height: 1.2;
  color: variables.$dark;
  font-size: fonts.$font-size-lg;
  font-family: fonts.$font-family-base;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
  &:disabled {
    cursor: default;
  }
}
