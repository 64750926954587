@use 'src/theme/mixins';
@use 'src/theme/variables';

  .ModalHeader {
  text-align: left;
  }

  .ModalBody{
    padding: 0.3rem 2rem 2rem 2rem !important;
    width: max-content;
  }

  h2 {
    color: variables.$dark
  }

  .TextContainer {
    text-align: left;
    padding-top: 1rem;
  }

  .ButtonContainer{
    text-align: center;
    margin-top: 1.5rem;
  }

  .PopUpButton{
    width: 165px;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
  }

  .Counter{
    padding: 0.3rem;
    padding-left: 2rem;
  }

  .CounterExpired{
    color: red;
  }

  .desriptionTextContainer{
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      padding-bottom: 0.3rem !important;
  }

  .CloseGlyph{
    float: right;
  }

  .inputRow{
    justify-content: center !important;
  }

