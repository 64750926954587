@use 'src/theme/variables';

.PreviewLink {
  z-index: 100;
  font-size: 1.5rem;
  text-align: right;
  color: variables.$primary;
  width: 48px;
  height: 48px;
  padding-top: 3px;
  padding-right: 9px;
  display: none;
}
