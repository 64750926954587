@use 'src/theme/mixins';
@use 'theme/_variables';

.icon {
  font-size: 100px;
  margin-bottom: variables.$spacing-xsmall;
  color: variables.$success;
}

.refinanceIcon {
  width: 93.68px;
  height: 107.375px;
}

@include mixins.media-breakpoint-up(lg) {
  .desc {
    height: 60px;
  }
}