@use 'src/theme/fonts';
@use 'src/theme/variables';

.menu-item-icon {
  display: inline;
  position: relative;
  left: -10px;
  bottom: 2px;
  font-size: 20px;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-overlay {
  top: 0;
}

.bm-menu-wrap {
  top: 0;
  left: 0;
  width: 403px;
}

.bm-burger-button {
  position: relative;
  width: 35px;
  height: 40px;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
  left: 25px;
  top: 20px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #3c3c3c;
  width: 2px !important;
  border-radius: 2px;
  height: 25px !important;
}

.bm-cross-button:hover .bm-cross {
  background: #5558af;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 0;
  padding-top: 75px;
  font-size: fonts.$font-size-lg;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
}

/* Individual item */
a.menu-item {
  display: block;
  color: #3c3c3c;
  padding: 15px 35px;
  border-top: 1px solid #cecece;
}

a.menu-item.selected {
  font-weight: bolder;
  outline: 3px solid variables.$primary;
  outline-offset: -3px;
}

a.menu-item:hover {
  text-decoration: none;
  color: variables.$primary;
}
