@use 'src/theme/variables';
@use 'src/theme/mixins';
@use 'src/theme/fonts';

/* Input Fields */
div.form-group {
  padding: variables.$spacing-small 0;

  /* Text Input */
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  input[type='date'],
  input[type='number'],
  input[inputMode='numeric'],
  input[inputMode='decimal'],
  button.dropdown-toggle,
  select {
    margin: 0;
    border: 2px solid variables.$border-color-light;
    border-radius: variables.$border-radius;
    height: 57px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.8;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.8;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    opacity: 0.8;
  }

  button.dropdown-toggle,
  select {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: right 1.3rem bottom 1.5rem, right 1rem bottom 1.5rem,
      right 2.5rem bottom 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 2.2rem;
    background-repeat: no-repeat;

    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  button.dropdown-toggle {
    text-align: left;
    background-color: #fff;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    width: 100%;
    border: 2px solid variables.$border-color-light;
    border-top-width: 0.5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    transform: none !important;
    bottom: auto !important;
    top: auto !important;
    margin-top: -5px !important;
  }

  button.dropdown-item {
    white-space: normal;
  }

  .dropdown-item {
    padding: 0.5rem 1.5rem;
  }

  input[type='checkbox'] {
    transform: scale(1.5);
    margin-top: 8px;
    border: 2px solid variables.$border-color-light;
    border-radius: variables.$border-radius;
  }

  input[type='checkbox'] ~ label {
    font-size: fonts.$font-size-base;
    margin-left: variables.$spacing-small;
  }

  small {
    font-size: fonts.$font-size-base;
  }
}
