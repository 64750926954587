@use 'src/theme/fonts';

.body {
  margin-bottom: 189px;
  small {
    font-size: fonts.$font-size-lg;
  }
}

.verifyForm {
  display: flex;
  justify-content: center;
}

.content {
  max-width: 600px;
  width: 100%;
}
