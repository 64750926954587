.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.offer {
  width: 140px;
  min-height: 140px;
  border-radius: 12px;
  background-color: #D8D8D8;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  transition: .3s all;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
  }
  span {
    text-align: center;
    color: #636363;
    transition: .3s all;
  }
}

.active {
  background-color: #6067B6 !important;
  span {
    color: #FFFFFF !important;
  }
}

.offer_name {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.offer_range {
  font-size: 12px;
  line-height: 24px;
}
