@use 'src/theme/mixins';
@use 'src/theme/variables';

.toastWrapper {
  z-index: 1055;
  right: 0;
  top: variables.$spacing-xlarge;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;

  @include mixins.media-breakpoint-down(md) {
    min-width: 100%;
  }
}
