@use 'src/theme/variables';
@use 'src/theme/mixins';

.NumberStepper {
  color: variables.$border-color;
}

.number {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 2px solid variables.$border-color;
  background-color: transparent;

  .check {
    display: none;
  }

  &.active, &.completed {
    margin-left: auto;
    margin-right: auto;
    border: none;
    background-color: variables.$success;
    color: variables.$success-contrast;
  }

  &.completed .check{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}

.numberText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  display: block;
  width: 100%;

  &.active {
    color: variables.$dark;
  }
}
