@use 'src/theme/variables';
@use 'src/theme/fonts';

.dropdown {
  position:absolute !important;
}

.docTable {
  margin-bottom: 3rem !important;
}

.secondTd {
    padding-top: 13px;
    width: 95px;
}

.docTableHead{
  border-bottom: 3px solid white !important;
}

.dateTitle {
  font-family: fonts.$font-family-bold;
  text-align: center;
  display: none;
}

.dateContent {
  text-align: center;
}

.docHeaders {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and
(max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr, .dateTitle, .dateContent {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	td {
		/* Behave  like a "row" */
		border: none;
		position: relative;
		padding-left: 50%;
	}

	td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}

  .dropdown {
    position: relative !important;
  }

  .docHeaders {
    text-align: center;
  }
}
