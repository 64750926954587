$success: #59d1a9;
$success-contrast: #ffffff;
$danger: #eb445a;
$warn: #fcdb01;
$warning: $warn;
$info: #59bdd1;

$primary: #5558af;
$secondary: $success;

$dark: #3c3c3c;
$medium: #9d9d9d;
$light: #f5f5f5;
$light-tint: #f0f0f0f0;
$yiq-text-dark: #3c3c3c;

$link-color: $primary;

$background-color: #fff;
$background-primary: #5558af;
$border-color-light: #d9d9d9;
$border-color: $border-color-light;
$border-color-dark: #707070;
$border-radius: 4px;

$spacing-xsmall: 0.25rem;
$spacing-small: 0.5rem;
$spacing-medium: 1rem;
$spacing-large: 2rem;
$spacing-xlarge: 4rem;

$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// bootstrap spacing
$spacers: (
  0: 0,
  1: $spacing-small,
  2: $spacing-medium,
  3: $spacing-large,
  4: $spacing-xlarge,
);

// bootstrap theme colors
$theme-colors: (
  'medium': #cecece,
);

$btn-disabled-opacity: 1;
