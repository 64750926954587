@use 'src/theme/mixins';
@use 'src/theme/variables';

.Header {
  background: variables.$background-color;
  padding-top: variables.$spacing-medium;
  padding-bottom: variables.$spacing-medium;
  height: 85px;
}

.phoneIcon {
  padding-right: variables.$spacing-small;
  font-size: 18px;
}

.logo {
  max-width: 350px;
}

.navLink {
  line-height: 1;
}

.menuButton:hover {
  display: inline;
}

.menuButton:hover {
  cursor: pointer;
}

.menuHamburger {
  color: #3c3c3c;
  font-size: 35px;
}

.menuHamburger:hover > path {
  stroke: #5558af;
}

.menuHamburger > path {
  stroke-width: 0.75;
  stroke: #3c3c3c;
}
