@use 'src/theme/variables';
@use 'src/theme/mixins';

.gridCol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accountCol {
  background-color: variables.$primary;
}

.content {
  max-width: 600px;
  width: 100%;
}

.loginCard {
  min-width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.loginForm {
  display: flex;
  justify-content: center;
}

.verifyEmailAlert {
  color: variables.$primary;
  border: 2px solid variables.$success;
}

@include mixins.media-breakpoint-down(xl) {
  .loginCard {
    margin: variables.$spacing-large;
    max-width: 600px;
    width: 100%;
    min-width: auto;
  }
}

@include mixins.media-breakpoint-down(md) {
  .accountCol {
    height: auto;
  }
  .loginCol {
    min-height: 600px;
    align-items: start;
  }
  .loginCard {
    margin: variables.$spacing-large variables.$spacing-medium;
    min-height: 0;
  }
}

.workflowOverlay {
  background-color: variables.$primary;
  color: white;
  text-align: center;
  height: 100%;
  padding-top: variables.$spacing-small;
  padding-bottom: variables.$spacing-large;
}

.leftTextSideBar {
  background-color: variables.$primary;
  color: white;
  height: 100%;
  padding-top: variables.$spacing-small;
  padding-bottom: variables.$spacing-large;
}

.changesBox {
  border: 2px solid white;
  width: 50%;
  max-width: 36em;
  padding: variables.$spacing-small;
  margin: auto;
  margin-top: variables.$spacing-small;
}

.question {
  margin-top: variables.$spacing-medium;
}

.yesOrNoButtons {
  justify-content: center;
  display: flex;
  gap: variables.$spacing-large;
  margin-top: variables.$spacing-medium;
}

.backButton {
  justify-content: flex-start;
  display: flex;
  margin-top: variables.$spacing-medium;
  margin-left: variables.$spacing-medium;

}

.stateButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: variables.$spacing-small;
  margin-top: variables.$spacing-medium;
}

.stateButton {
  min-width: 12em;
}

.lookupInput {
  max-width: 16em;
  min-width: 16em;
}

.lookupInputs {
  text-align: center;
  display: grid;
  justify-items: center;
}

.inputLabel {
  align-items: start;
}

.disabledInput {
  color: gray !important;
  opacity: .5 !important;
}

.invalidInput {
  border:1px solid red;
}

ul{
  margin-top: 10px;
}

.textTutorialLink{
  justify-content: flex-start;
}

@media screen and (max-width: 600px){
    .textTutorialLink {
        text-align: center;
        align-items: center;
    }
}