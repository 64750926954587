@use 'theme/_variables';
@use 'theme/_mixins';

.banner {
  width: 100%;
  height: 30vh;
  max-height: 425px;
  background-size: cover;
  background-position: center center;
}

.content {
  ul {
    list-style-type: none;
    padding: 0;
    padding-left: 25px;
    margin: 1.5em 0;

    li {
      margin: 0.5em 0;
      
      &::before {
        content: '';
        display: block;
        width: 37.5px;
        height: 35px;
        background-image: url("/assets/icon/green-check.png");
        background-size: 26px;
        background-position: top left;
        background-repeat: no-repeat;
        float: left;
      }

      &::after {
        content: '';
        clear: both;
        display: block;
      }
    }
  }
}