@use 'src/theme/mixins';
@use 'src/theme/variables';

.fullHeight {
  height: 100vh;
}

@media print {
  .print header, .print footer {
    display: none;
  }
}
